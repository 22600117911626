.CompanyGuarantee>div>.StandartTitle{
    padding-bottom: 50px;
}
.CompanyGuarantee>.StandartPage>.CompanyGuaranteePart01Text{
    padding-bottom: 30px;
}

.CompanyGuaranteeText{
    font-weight: 400;
    font-size: 16px;
    color: black;
    line-height: 27px;
    padding-top: 60px;
    padding-bottom: 42px;
    text-align: center;
}

.CompanyGuaranteeImg01{
    width: 100%;
    height: 500px;
    background: url("../../../static/img/CompanyGuarantee/CompanyGuarantee01.png") center;
}

.CompanyGuaranteePart01{
    position: relative;
    margin-top: -161px;
    border-radius: 4px;
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 45px 0 rgba(42, 42, 42, 0.25);
    background-color: rgba(256,256,256,0.8);
    z-index: 10;
}

.CompanyGuaranteePart01TopBackground{
    position: absolute;
    background: url("../../../static/img/CompanyGuarantee/CompanyGuarantee01.png") bottom;
    filter: blur(10px);
    z-index: 5;
    width: 100%;
    height: 163px;
    max-width: 1024px;
}


.CompanyGuaranteePart01>.CompanyGuaranteePart01Text{
    padding-bottom:  20px;
}

.CompanyGuaranteePart02>.StandartPageIn>.CompanyGuaranteePart01Text{
    padding-bottom:  20px;
}


.CompanyGuaranteePart01Title{
    font-weight: 200;
    font-size: 37px;
    color: #151544;
    line-height: 35px;
    padding-top: 25px;
    padding-bottom: 40px;
    padding-left: 38px;
    padding-right: 40px;
}

.CompanyGuaranteePart01Text{
    position: relative;
    font-weight: 400;
    font-size: 17px;
    color: #151544;
    padding-left: 38px;
    padding-right: 40px;
    line-height: 27px;
}

.CompanyGuaranteeImg03>img{
    width: 100%;
    text-align: center;
    margin: auto;
}

.CompanyGuaranteeImg02>img{
    width: 100%;
    margin: auto;
}

.CompanyGuaranteePart01>img{
    display: none;
}

@media only screen and (max-width: 1000px) {
    .CompanyGuaranteePart01Title{
        font-size: 30px;
    }
    .CompanyGuaranteePart01TopBackground{
        display: none;
    }
    .CompanyGuaranteePart01{
        margin-top: 0;
    }
    .CompanyGuaranteeImg01{
        display: none;
    }
    .CompanyGuaranteePart01>img{
        display: block;
        width: 100%;
    }

}
@media only screen and (max-width: 600px) {
    .CompanyGuaranteePart01Text,.CompanyGuaranteePart01Title{
        padding-right: 20px;
        padding-left: 20px;
    }
}
