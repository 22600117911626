.CheckLanguage{
    display: flex;
    grid-column: 3;
    padding-right: 5px;
    align-items: center;
    cursor: pointer;
}

.CheckLanguageHover:hover{
    background-color: #ffc920;
}

.CheckLanguageImg{
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 5px;
}

.CheckLanguageImg>img {
    border-radius: 9px;
}

@media only screen and (max-width: 800px) {
    .CheckLanguageTitle {
        display: none;
    }
    .CheckLanguageOutOut>.CheckLanguageOut>a>.CheckLanguage>.CheckLanguageTitle{
        display: block;
    }
}

.CheckLanguageOut{
    box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    border-radius: 3px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 30px;
    left: -10px;
    z-index: 50;
}

.CheckLanguageOutOut{
    position: absolute;
    top: 40px;
    left: 85px;
    display: flex;
    /*z-index: 50;*/
    justify-content: flex-end;
}

.CheckLanguageNo{
    display: none;
}


.CheckLanguageYellowTriangle{
    border: 5px solid transparent;	border-top: 8px solid #ffc920;
    position: relative;

}

.VisibleAllDark{
   content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 50;
    background: rgba(00,00,00,0.9);
    /*backdrop-filter: blur(10px);*/
}

.AllBlackOut{
    filter: blur(10px);
}

.CheckLanguageYes{

}