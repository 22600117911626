.TopEarn{
    width: 100%;
    height: 767px;

    position: relative;
}
.TopEarnBackGround{
    background-size: cover;
    filter: blur(2px);
    background: url("../../static/img/IndexMain/BaalIndexTop.svg") center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
}

.TopEarnTopLeftButtons>div>a{
    text-decoration: none;
}

.TopEarnBackGroundBlack{
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 5;
    position: absolute;
}

.TopEarnIn{
    width: 1040px;
    margin: auto;
    padding-top: 24px;
    padding-bottom: 117px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 456px 150px;
    grid-row-gap: 90px;
    position: relative;
    z-index: 10;
}

.TopEarnTopLeft{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.TopEarnTopLeftButtons{
    display: flex;
}

.TopEarnTopLeftButtons>.ButtonOut{
    padding-right: 22px;
}

.TopEarnTopLeftButtons>.ButtonOut>a>.ButtonYellow>img{
    padding-left: 11px;
}

.TopEarnTopLeftButtons>.ButtonOut>a>.ButtonTransparency{
    font-size: 19px;
}

.TopEarnTop{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
}

.TopEarnTopRightTitle{
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    line-height: 1.65;
    color: white;
    position: relative;
    top: 15px;
}

.TopEarnTopRightCurrencies{
    display: flex;
    flex-wrap: wrap;
    max-width: 440px;
    justify-content: center;
    margin: auto;
}

.TopEarnTopRightCurrenciesElement{
    width: 110px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: white;
    margin-top: 36px;

}

.TopEarnTopRightCurrenciesElement>img{
    height: 80px;
    padding-bottom: 11px;
}

.TopEarnTopLeftTitle{
    font-size: 52px;
    font-weight: 500;
    color: white;
    padding-bottom: 20px;
}
.TopEarnTopLeftText{
    font-size: 19px;
    font-weight: 400;
    color: white;
    padding-bottom: 35px;
    line-height: 30px;
}

.TopEarnStatistic{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-row-gap: 20px;
}
@media only screen and (max-width: 1100px) {
    .TopEarnStatistic{
        grid-template-columns: auto auto auto auto;
    }
}
@media only screen and (max-width: 900px) {
    .TopEarnStatistic{
        grid-template-columns: auto auto auto;
    }
}
@media only screen and (max-width: 600px) {
    .TopEarnStatistic{
        grid-template-columns: auto auto;
    }
}
@media only screen and (max-width: 400px) {
    .TopEarnStatistic{
        grid-template-columns: auto;
    }
}

.TopEarnStatisticElement{
    padding-right: 20px;
}

.TopEarnStatisticElementTop{
    font-size: 30px;
    font-weight: 500;
    color: #ffc920;
}

.TopEarnStatisticElementText{
    font-size: 14px;
    font-weight: 400;
    color: white;
}


@media only screen and (max-width: 1100px) {
    .TopEarnTop{
        grid-column-gap: 10px;
    }
    .TopEarnIn{
        width: auto;
        padding-right: 15px;
        padding-left: 15px;
        grid-template-rows: auto;
    }

    .TopEarnStatistic{
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .TopEarn{
        height: auto;
    }
}


@media only screen and (max-width: 930px) {
    .TopEarnTopLeftText {
        line-height: 40px;
        font-size: 23px;
    }

    .TopEarnTopRightCurrenciesElement{
        min-width: 120px;
    }
}

@media only screen and (max-width: 800px) {
    .TopEarnIn{
        padding: unset;
        grid-row-gap: 10px;
    }
    .TopEarnTopLeftTitle{
        padding: 15px 15px 0 15px;
    }
    .TopEarnTopLeftText{
        padding: 15px 15px 0 15px;
    }
    .TopEarnTopLeftButtons{
        padding: 15px 15px 0 15px;
    }
    .TopEarnTop{
        grid-template-columns: auto;
    }
    .TopEarnTopLeftTitle{
        font-size: 31px;
    }
    .TopEarnTopLeftText{
        font-size: 15px;
    }
    .TopEarnTopRightTitle{
        display: none;
    }
    .TopEarnTopRight{
        overflow: hidden;
    }
    .TopEarnTopRightCurrencies{
        flex-wrap: nowrap;
        width: auto;
    }
    .TopEarnTopRightCurrenciesElement{
        width: 130px;
        margin-left: 20px;
    }
    .TopEarnStatistic{
        padding-left: 20px;
        padding-right: 20px;
    }
    .TopEarnStatisticElement{
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 450px) {
    .TopEarnTopLeftButtons{
        flex-direction: column;
        align-items: center;
    }
    .TopEarnTopLeftButtons>.ButtonOut{
        padding-bottom: 10px;
        padding-right: 0;
    }
    .ButtonDemo>a>.ButtonTransparency{
        width: 205px;
    }
    .TopEarnTopLeftButtons>div>a>div{
        padding-left: 10px;
        padding-right: 10px;
    }
    .TopEarnTopLeftButtons>.ButtonOut,.TopEarnTopLeftButtons,.TopEarnTopLeftText,.TopEarnTopLeftTitle{
        padding-left: 5px;
        padding-right: 5px;
    }
}
