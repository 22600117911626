.TraderConditionsText{
    font-size: 17px;
    color: black;
    line-height: 30px;
    margin-top: 33px;
}

.TraderConditionsH2{
    font-size: 37px;
    color: #151544;
    line-height: 35px;
    text-align: center;
    margin-top: 33px;
    margin-bottom: 40px;

}

.TraderConditionsH2Info{
    background-color: white;
    box-shadow: 0 10px 45px 0 #e0e6f4;
    padding: 20px 27px 30px 28px;
    text-align: center;
}

.TraderConditionsTable{
    width: 100%;
    border-spacing: 0;
    text-align: left;
    margin-top: 30px;
}
.TraderConditionsTable>thead>tr>th{
    height: 50px;
    color: white;
    background-color: #151544;
    font-weight: 700;
    font-size: 17px;
    text-align: left;
    padding-left: 20px;
}

.TraderConditionsTable>tbody>tr>td{
    height: 50px;
    background-color: white;
    text-align: left;
    padding-left: 20px;
    color: black;
    font-size: 15px;
}

.TraderConditionsTable>tbody>tr>td:nth-of-type(3){
    color: #71d800;
}

.TraderConditionsTable>tbody>tr>td:nth-of-type(4){
    color: #ff1f1f;
}

.TraderConditionsTable>tbody>tr>td:nth-of-type(3),.TraderConditionsTable>tbody>tr>td:nth-of-type(4),.TraderConditionsTable>tbody>tr>td:nth-of-type(5){
    width: 100px;
}

.TraderConditionsForPhone{
    display: none;
}

@media only screen and (max-width: 800px) {
    .TraderConditionsForPhone{
        display: block;
    }
    .TraderConditionsTable{
        display: none;
    }

}

.TraderConditionsTable{
    margin-top: 20px;
}

.TraderConditionsForPhoneElement{
    margin-top: 34px;
}

.TraderConditionsForPhoneTitle{
    font-size: 17px;
}

.TraderConditionsForPhoneText{
    font-size: 13px;
}

.TraderConditionsForPhoneFlex{
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: space-around;
}

@media only screen and (max-width: 500px) {
    .TraderConditionsForPhoneFlex{
        justify-content: space-between;
    }
    .TraderConditionsH2{
        font-size: 25px;
    }
    }


.TraderConditionsForPhoneFlexElementText{
    font-size: 13px;
    line-height: 23px;
    color: #b1b1b1;
}

.TraderConditionsForPhoneFlexAsk{
    font-size: 17px;
    color: #ff1f1f;
}
.TraderConditionsForPhoneFlexBid{
    font-size: 17px;
    color: #6dda00;
}


