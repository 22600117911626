.EducationTop{
    border: 1px solid #e9e9e9;
    padding: 0 30px 30px 30px;
    margin: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.EducationTopElement{
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    column-gap: 16px;
    width: 120px;
    margin-top: 30px;
    margin-right: 10px;
    margin-left: 10px;
}

.EducationTopElement>div{
    width: 100%;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    justify-content: center;
    background-size: contain;
    background-position: center;
}

.EducationTopElementText{
    margin-top: 20px;
}
.EducationTopElementImg01{
    width: 50px;
    height: 51px;
    background-image: url("../../../static/img/Education/EducationCrypto.svg");
}

.EducationTopElementActive{
    border-bottom: yellow;
}

.EducationTopElementActive>div>.EducationTopElementText{
    font-weight: 500;
    color: black;
}

.EducationTopElementActive>div>.EducationTopElementImg01{
    background-image: url("../../../static/img/Education/EducationCryptoActive.svg");

}
.EducationTopElementImg02{
    width: 49px;
    height: 48px;
    background-image: url("../../../static/img/Education/EducationGraphic.svg");
    background-size: cover;
}

.EducationTopElementActive>div>.EducationTopElementImg02{
    background-image: url("../../../static/img/Education/EducationGraphicActive.svg");

}
.EducationTopElementImg03{
    width: 46px;
    height: 46px;
    background-image: url("../../../static/img/Education/EducationBitcoin.svg");
    background-size: cover;
}

.EducationTopElementActive>div>.EducationTopElementImg03{
    background-image: url("../../../static/img/Education/EducationBitcoinActive.svg");

}
.EducationTopElementImg04{
    width: 37px;
    height: 50px;
    background-image: url("../../../static/img/Education/EducationIndicators.svg");
    background-size: cover;
}

.EducationTopElementActive>div>.EducationTopElementImg04{
    background-image: url("../../../static/img/Education/EducationIndicatorsActive.svg");

}
.EducationTopElementImg05{
    width: 78px;
    height: 51px;
    background-image: url("../../../static/img/Education/EducationTerminal.svg");
    background-size: cover;
}

.EducationTopElementActive>div>.EducationTopElementImg05{
    background-image: url("../../../static/img/Education/EducationTerminalActive.svg");

}
.EducationTopElementImg06{
    width: 51px;
    height: 51px;
    background-image: url("../../../static/img/Education/EducationBook.svg");
    background-size: cover;
}

.EducationTopElementActive>div>.EducationTopElementImg06{
    background-image: url("../../../static/img/Education/EducationBookActive.svg");
}
