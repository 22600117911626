.IndexPartners{
    width: 100%;
    height: 305px;
}

.IndexPartnersIn{
    max-width: 1040px;
    margin: auto;
    height: 100%;
}

.IndexPartnersLeftTitle{
    font-weight: 200;
    font-size: 37px;
    color: #151544;
    padding-top: 40px;
}
.IndexPartnersLeftText{
    font-weight: 400;
    font-size: 17px;
    color: #151544;
    padding-top: 30px;
    padding-bottom: 36px;
}

.IndexPartnersIn{
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.IndexPartnersRight>img{
    position: relative;
    top: -10px;
    z-index: 20;
}

.IndexPartnersLeftIMG{
    display: none;
}

@media only screen and (max-width: 1200px) {
   .IndexPartnersIn{
       padding-left: 20px;
   }
}

@media only screen and (max-width: 1000px) {
    .IndexPartnersRight{
        position: relative;
    }
    .IndexPartnersRight>img{
        position: absolute;
        right: 2px;
    }
    .IndexPartnersIn{
        display: grid;
        grid-template-columns: auto 330px;
    }

}
@media only screen and (max-width: 800px) {
    .IndexPartnersLeft>div{
        padding-top: 10px;
    }

}

@media only screen and (max-width: 750px) {
    .IndexPartnersLeft>div{
        padding-top: 10px;
    }
    .IndexPartnersIn{
        display: block;
    }
    .IndexPartners{
        height: auto;
    }
    .IndexPartnersRight>img{
        position: relative;
    }
    .IndexPartnersRight{
        display: none;
    }
    .IndexPartnersLeftIMG{
        display: block;
        width: 280px;
        padding-top: 20px;
    }
    .IndexPartnersLeftText{
        padding-right: 20px;
    }
}

@media only screen and (max-width: 400px) {
    .IndexPartnersIn{
        padding-left: 10px;
    }
}

@media only screen and (max-width: 340px) {
    .IndexPartnersLeftIMG{
        width: 245px;
    }
}

.ButtonBlackOpacity>a{
    text-decoration: none;
    color: inherit;
}