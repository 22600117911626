:root {
    --fontfamily: Roboto;
}
.HrTopAbove{width: 90%; height: 1px;  background-color: #eef1f2; margin: 20px auto 20px auto;}
.HrTopAboveAll{width: 100%; height: 1px;  background-color: #eef1f2; margin: 20px auto 20px auto;}

.FooterAll{
    margin-top: 55px;
}

.footer_links{display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-column-gap: 30px;
    grid-template-rows:auto;
    margin: auto;
    max-width: 1078px;}
/* Footer*/
.footer_title{ font-family: Roboto, sans-serif;font-weight: bold; color: black }

.footer_bottom{display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    align-items: center;justify-content: space-between;
    max-width: 1078px;
    margin: auto;}
.footer_logo{font-family: Roboto, sans-serif;
    font-size: 17px; align-self: center;
    grid-column: 1/3;}
.footer_gurs{
    text-align: center;
    float:left;
    font-family: var(--fontfamily);
    font-size: 13px; grid-column: 5;
    display: flex;
    flex-direction: row;}
.footer_gurs>div{
    margin-right: 16px;
}
.footer_element{font-family: Roboto, sans-serif;font-weight: 400; color: rgb(10,121,191); margin-top: 15px;}
@media only screen and (max-width: 1150px) {
    .footer_links{margin: 0 50px 0 50px;}
    .footer_bottom{margin: 0 50px 0 50px;}
}
@media only screen and (max-width: 1000px) {
    .footer_logo{
        grid-column: 1/5;}}

@media only screen and (max-width: 1000px) {
    .FooterAll{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .footer_links{
        margin-bottom: 30px;
    }
    .FooterAll>.HrTopAbove{
        display: none;
    }
    .footer_links{
        grid-template-columns: auto auto auto auto;
        position: relative;
        }
    .footer_column_1:nth-of-type(5){
        grid-column: 3;
        position: relative;
        top: -80px;
    }
    .footer_column_1:nth-of-type(6){
        grid-column: 4;
        position: relative;
        top: -80px;
    }


}

@media only screen and (max-width: 400px) {
    .footer_bottom{margin: 0 10px 0 10px;}
}

@media only screen and (max-width: 750px) {
    .footer_links{
        grid-template-columns: auto auto auto;
        grid-row-gap: 30px;
    }
    .footer_column_1:nth-of-type(5){
        position: static;
        grid-column: auto;

    }
    .footer_column_1:nth-of-type(6){
        position: static;
        grid-column: auto;
    }
}

@media only screen and (max-width: 550px) {
    .footer_links{
        grid-template-columns: auto auto;
        grid-row-gap: 30px;
        margin-left: 15px;
        margin-right: 5px;
    }
}

.footer_column_1>a{
    text-decoration: none;
}


