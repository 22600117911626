.CompanyNewsGridYears{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.CompanyNewsGridYearsElement{
    font-weight: 400;
    font-size: 17px;
    color: #4a90e2;
    margin-top: 30px;
    border-bottom: 1px #4a90e2 dashed;
    cursor: pointer;
    margin-right: 25px;
    margin-left: 25px;
    padding: 1px 1px 1px 1px;
    text-align: center;
}

.CompanyNewsGridYearsElementActive{
    font-weight: 700;
    font-size: 17px;
    background-image: linear-gradient(to top,#192b71,#1d0d56);
    border-radius: 10px;
    color: white;
    text-decoration: none;
    cursor: unset;
    padding: 1px 15px 1px 15px;
    border-bottom: none;
}

.CompanyNewsGridNews{
    padding-top: 30px;
    padding-bottom: 30px;
}

.CompanyNewsGridNewsElementTitle{
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: black;
}

.CompanyNewsGridNewsElementDate{
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: black;
}

.CompanyNewsGridNewsElementText{
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #353b50;
}

.CompanyNewsGridNewsElement{
    padding-bottom: 36px;
    display: grid;
    grid-template-columns: 100px auto;
    grid-column-gap: 40px;
}

@media only screen and (max-width: 700px) {
    .CompanyNewsGrid{
        display: grid;
        grid-template-columns: auto;
    }
    .CompanyNewsGridYears{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-top: 15px;
    }
    .CompanyNewsGridYearsElement{
        min-width: 50px;
    }
}

@media only screen and (max-width: 450px) {
    .CompanyNewsGridNewsElement{
        display: block;
    }
    .CompanyNewsGridNewsElementDate{
        margin-bottom: 20px;
    }
}
