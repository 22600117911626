.TerminalWebterminal{
    font-family: Geometria, sans-serif;
    max-width: 1078px;
    margin: auto;
}
.TerminalWebterminalIn{

}

.TerminalWebterminalPart01Top{
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
}
@media only screen and (max-width: 1050px) {
    .TerminalWebterminalPart01Top{
        grid-template-columns: auto;
        grid-row-gap: 0;
        padding-bottom: 20px;

    }
    .TerminalWebterminalPart01Top>img{
        grid-row: 3;
    }
    .TerminalWebterminalPart01Top>.TerminalWebterminalInPartStore{
        margin-bottom: 10px;
        margin-top: 30px;
    }
}

.TerminalWebterminalPart01Top>img{
    padding-top: 10px;

}

.TerminalWebterminalInPart01Right>.TerminalWebComponent3IMG>.TerminalWebComponent3IMGCircles{
    margin-bottom: 30px;
}

@media only screen and (max-width: 970px) {
    .TerminalWebterminalInPart01Right>.TerminalWebComponent3IMG>.TerminalWebComponent3IMGImage>.TerminalWebComponent3IMGImageIn>img{
        width: 100%;
        object-fit: contain;
    }
}

@media only screen and (max-width: 700px) {
    .TerminalWebterminalPart01Top{
        justify-content: center;
    }
    .TerminalWeb>.TerminalIn>.TerminalWebterminalPart01{
        padding-right: 10px;
        padding-left: 10px;
    }
    .TerminalInPart01LeftText{
        padding-right:  20px;
    }
}



.TerminalWebterminalInPartStore{
    padding-top: 0;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.TerminalWebterminalInPartStore>.TerminalInPart01LeftStore{
    margin-right: 30px;
}


.TerminalWebterminalInPartStore>.TerminalInPart01LeftStore,.TerminalWebterminalInPartStore>.TerminalInPart01LeftText{
    padding-bottom: 10px;
}

@media only screen and (max-width: 400px) {
    .TerminalWebterminalPart01Top>img{
        display: none;
    }
}

.TerminalWebterminalPart01TextMain{
    line-height: 27px;
}

.TerminalWebterminalPart02Out{
    background-color: #f2f2ee;
}

.TerminalWebterminalPart02{
    display: grid;
    grid-template-columns: 600px auto;
    grid-column-gap: 40px;
    padding-top: 50px;

}

.TerminalWebterminalPart02>.TerminalWebterminalPart02Left>img{
    width: 100%;
    object-fit: contain;
}

.TerminalWebterminalPart03{
    display: grid;
    grid-template-columns: auto 600px;
    grid-column-gap: 40px;
    padding-top: 40px;
}

.TerminalWebterminalPart03>.TerminalWebterminalInPart03Right>img{
    width: 100%;
    object-fit: contain;
}


.TerminalWebterminalPart02Left,.TerminalWebterminalInPart03Right{
    position: relative;
    overflow: hidden;
}

    .TerminalWebterminalPart02{
        padding-left: 0;
        grid-template-columns: auto 300px;
        position: relative;
    }
    .TerminalWebterminalPart02>.TerminalWebterminalPart02Left>img{
        width: 676px;
        position: absolute;
        right: -3px;
    }
    .TerminalWebterminalPart02:before{
        content: '';
        height: 100%;
        width: 500px;
        position: absolute;
        top: 0;
        left: -500px;
        z-index: 10;
        background-color: #edf1f1;
    }
    .TerminalWebterminalPart03{
        padding-right: 0;
        grid-template-columns: 300px auto;
        position: relative;
    }
    .TerminalWebterminalPart03>.TerminalWebterminalInPart03Right{overflow: hidden;}


    .TerminalWebterminalPart03>.TerminalWebterminalInPart03Right>img{
        width: 676px;
        position: absolute;
        left: -3px;
        overflow: hidden;

    }

.TerminalWebterminalInPart02Right>ul,.TerminalWebterminalInPart03Left>ul{
    padding: 0;
}

.TerminalWebterminalInPart02RightAndroidDesktop>.TerminalTitleH2{
    margin-top: 40px;
}


.TerminalWebterminalInPart02Right>ul>li,.TerminalWebterminalInPart03Left>ul>li{
    line-height: 27px;
    color: #413422;
    font-size: 16px;
    list-style: none;
    margin-top: 25px;
}

.TerminalWebterminalInPart02Right>ul>li:before,.TerminalWebterminalInPart03Left>ul>li:before{
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #ff9306;
    margin-right: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    position: relative;
    top: 3px;
}

.TerminalWebterminalInPart02Right>.TerminalTitleH2,.TerminalWebterminalInPart03Left>.TerminalTitleH2{
    padding: 0 30px 0 0;
    font-size: 24px;
    line-height: 28px;
    color: #413422;
}

@media only screen and (max-width: 600px) {
    .TerminalWebterminalPart02,.TerminalWebterminalPart03{
        display: flex;
        background-color: white;
        flex-direction: column;
    }
    .TerminalWebterminalPart03{
        padding-left: 0;
    }
    .TerminalWebterminalPart02Left{
        order: 2;
    }
    .TerminalWebterminalInPart03Right{
        order: 2;
    }
    .TerminalWebterminalInPart02Right,.TerminalWebterminalInPart03Left{
        padding-left: 20px;
        padding-right: 20px;
    }
    .TerminalWebterminalPart02>.TerminalWebterminalPart02Left>img{
        position: static;
        width: 100%;
    }
    .TerminalWebterminal{
        margin-left: 0;
        margin-right: 0;
    }
    .TerminalWebterminalPart03{
        display: flex;
        flex-direction: column;
    }

    .TerminalWebterminalPart03>.TerminalWebterminalInPart03Right>img{
        position: static;
        width: 100%;
    }
    .TerminalWebterminalPart03:after{
        width: 0;
    }
    .TerminalWebterminalPart02{
        padding-right: 0;
    }
}






@media only screen and (max-width: 500px) {
    .TerminalWebterminalInPartStore{
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 700px) {
    .TerminalWebterminalInPartStore{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .TerminalWebterminalPart01Top>img{
        margin: auto;
    }
}


