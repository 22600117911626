

.PartnersText{
    font-weight: 400;
    font-size: 17px;
    color: black;
    line-height: 30px;
    text-align: center;
}

.PartnersText01{
    padding-top: 30px;
    padding-bottom: 60px;
}

.PartnersGrid01{
    display: grid;
    width: 550px;
    margin: auto;
    grid-template-columns: 350px auto;
    grid-template-rows: 1fr 1fr;
}

.PartnersGridIMG01{
    grid-row: 1/3;
    width: 100%;
    background: url("../../static/img/Partners/Partners01.png") center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 272px;
}


.PartnersGridIMG02>img{
    width: 100%;
    max-width: 650px;
    margin: auto;
    display: flex;
    justify-content: center;
}

.PartnersGridIMG03>img{
    width: 100%;
    max-width: 600px;
    margin: auto;
    display: flex;
    justify-content: center;

}
.PartnersGridIMG04>img{
    width: 100%;
    max-width: 461px;
    margin: auto;
    display: flex;
    justify-content: center;
}

.PartnersGridIMG05>img{
    width: 100%;
    max-width: 608px;
    margin: auto;
    display: flex;
    justify-content: center;
}

.PartnersGridText{
    font-weight: 500;
    font-size: 17px;
    color: black;
}

.PartnersGridText01{
    padding-top: 60px;
    padding-left: 20px;
}

.PartnersGridText02{
    padding-top: 20px;
    padding-left: 20px;
}


@media only screen and (max-width: 500px) {
    .PartnersGrid01{
        width: auto;
        height: auto;
        grid-template-columns: 200px auto;
        justify-content: center;
    }
    .PartnersGridIMG01{
        height: auto;
    }
    .PartnersGridText01{
        padding: 40px 0 0 10px;
        font-size: 15px;

    }

    .PartnersGridText02{
        padding: 10px 0 0 10px;
        font-size: 15px;
    }
}


.PartnersText02{
    padding-top: 27px;
    padding-bottom: 52px;
}

.PartnersText03{
    padding-top: 26px;
    padding-bottom: 40px;
}

.PartnersTextH2{
    font-weight: 200;
    font-size: 37px;
    color: #151544;
}

.PartnersTextH3{
    font-weight: 500;
    font-size: 19px;
    color: #151544;
    line-height: 27px;
}

.PartnersTextGrid02{
    font-weight: 400;
    font-size: 17px;
    color: #151544;
    line-height: 27px;

}

.PartnersGrid02{
    margin-top: 40px;
    object-fit: contain;
    border-radius: 4px;
    box-shadow: 0 10px 45px 0 rgba(42, 42, 42, 0.25);
    background-color: #ffffff;
}

.PartnersGrid02>.PartnersTextGrid02{
    padding-bottom: 40px;
}

.PartnersGrid02>.PartnersTextH3{
    padding-top: 28px;
    padding-bottom: 37px;
}

.PartnersGrid03,.PartnersGrid04,.PartnersGrid05{
    margin-top: 30px;
    padding-top: 30px;
    object-fit: contain;
    border-radius: 4px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 45px 0 rgba(42, 42, 42, 0.25);
    background-color: #ffffff;

}

.PartnersGrid02>div,.PartnersGrid03>div,.PartnersGrid04>div,.PartnersGrid05>div{
    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (max-width: 400px) {
    .PartnersGrid02>div,.PartnersGrid03>div,.PartnersGrid04>div,.PartnersGrid05>div{
        padding-left: 15px;
        padding-right: 15px;
    }
}


.PartnersGrid03>.PartnersTextGrid02{
    padding-bottom: 20px;
}

.PartnersGrid03>.PartnersTextH3{
    padding-top: 19px;
    padding-bottom: 37px;
}

.PartnersGrid04>.PartnersTextGrid02{
    padding-bottom: 20px;
}

.PartnersGrid04>.PartnersTextH3{
    padding-top: 19px;
    padding-bottom: 37px;
}
.PartnersGrid05>.PartnersTextGrid02{
    padding-bottom: 20px;
}

.PartnersGrid05>.PartnersTextH3{
    padding-top: 19px;
    padding-bottom: 37px;
}

.PartnersRedPage{
    background-image: linear-gradient(61deg, #ff4040, #ff713a);
    color: white;
}

.PartnersRedPageTitle{
    font-weight: 200;
    font-size: 37px;
    line-height: 25px;
    padding-top: 40px;
    padding-bottom: 24px;
}

.PartnersRedPageFlex{
    padding-bottom: 50px;
}
.PartnersRedPageFlexItem{
    display: flex;
}

.PartnersRedPageFlexItemText{
    font-weight: 400;
    font-size: 15px;
    line-height: 32px;
}

.PartnersRedPageFlexItemImg{
    position: relative;
    top: 6px;
    margin-right: 10px;
}


@media only screen and (max-width: 600px) {
    .PartnersTextH2{
        font-size: 30px;
    }
}