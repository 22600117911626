.TradingFAQButtonButtonAllDivActive,.TraderFAQElementTitle{
    cursor: pointer;

}

.TradingFAQButtonButtonAllDivActive{
    color: #218ed2;
}


.FAQElement{
    cursor: pointer;
}

.TradingFAQPartTitle {
    padding-bottom: 15px;
    padding-left: 0;
    font-family: GothamPro, sans-serif;
    font-weight: 400;
    font-size: 38px;
    color: rgb(24,24,24);
}

.TitleGlobalFlex{
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 650px) {
    .TitleGlobalFlex{
        flex-direction: column;

    }

    .TitleGlobalFlex>.TradingFAQButtonButtonAll{
        margin-top: 0;
    }
}
@media only screen and (max-width: 400px) {
    .TitleGlobalFlex>.TradingFAQButtonButtonAll{
        grid-template-columns: 157px;
        grid-row-gap: 10px;
        padding-bottom: 10px;
    }
}



.TradingFAQButtonButtonAll{
    margin-top: 36px;
    display: grid;
    grid-template-columns: 157px 157px;
    grid-column-gap: 20px;
    position: absolute;
    right: 0;
    width: 334px;
}

.TradingFAQButtonButtonAll>div>img{
    padding-right: 15px;
}

.TradingFAQButtonButtonAll>div{
    width: 129px;
    border-radius: 4px;
    background-color: white;
    padding: 14px 15px 16px 13px;
    height: 22px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 800px) {
    .TradingFAQButtonButtonAll{
        position: relative;
    }
}

.TraderFAQ{
    position: relative;
    max-width: 1078px;
    margin: auto;
    padding-bottom: 15px;
}

@media only screen and (max-width: 1150px) {
    .TraderFAQ{
        margin-left: 27px;
        margin-right: 27px;
    }
}

.TradingFAQPartElement{
    background-color: white;
    padding: 8px 58px 2px 19px;
    margin-bottom: 10px;
}

.TradingFAQPartElement>.TradingFAQPartElementTitle{
    font-size: 18px;
    color: black;
    display: grid;
    grid-template-columns: 18px auto;
    grid-column-gap: 9px;
}

.TradingFAQPartElement>.TradingFAQPartElementText{
    display: none;
}

.TradingFAQPartElementTitleIMG{
    background-image: url("../../../static/img/FAQ/Question.svg");
    background-size: contain;
    width: 18px;
    height: 10px;
    margin-top: 4px;
}



.TradingFAQPartElementActive>.TradingFAQPartElementTitle{
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 17px;
    color: rgb(24,24,24);
}

.TradingFAQPartElementActive>.TradingFAQPartElementText{
    display: block;
    margin-top: 20px;
    font-size: 14px;
    color: black;
    background-color: #f5f5f5;
    padding: 18px 21px 28px 28px;
}

.TradingFAQPartElementActive>.TradingFAQPartElementTitle>.TradingFAQPartElementTitleIMG{
    background-image: url("../../../static/img/FAQ/QuestionActive.svg");

}
