.HowToMoneyInOut{
    background-color: #f7f7f7;
    padding-top: 51px;
    padding-bottom: 60px;
}

.HowToMoneyInOutGrid{
    display: grid;
    grid-template-columns: 235px auto;
}



.HowToMoneyInOutGridTitle{
    font-weight: 200;
    font-size: 32px;
    color: #151544;
    line-height: 40px;
}

.HowToMoneyInOutGridFlex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.HowToMoneyInOutGridFlexElement{
    width: 60px;
}

@media only screen and (max-width: 1024px) {
    .HowToMoneyInOutGrid{
        grid-template-columns: auto;
        text-align: center;
    }
    .HowToMoneyInOutGridTitle{
        padding-bottom: 30px;
        font-size: 25px;

    }
}

.HowToMoneyInOutGridFlexElement01{

}

.HowToMoneyInOutGridFlexElement23{

}