
.DirectInvestmentImg01{
    width: 100%;
    height: 500px;
    background: url("../../../static/img/DirectInvestment/DirectInvestment01.png") center;
}

@media only screen and (max-width: 1000px) {
    .DirectInvestmentImg01{
        background: url("../../../static/img/DirectInvestment/DirectInvestment011000.jpg") no-repeat center;
        height: 244px;
    }
}
@media only screen and (max-width: 800px) {
    .DirectInvestmentImg01{
        background: url("../../../static/img/DirectInvestment/DirectInvestment01800.jpg") center;
    }
}
@media only screen and (max-width: 320px) {
    .DirectInvestmentImg01{
        background: url("../../../static/img/DirectInvestment/DirectInvestment01320.jpg") center;
    }
}

.DirectInvestmentPart01{
    margin-top: -161px;
    border-radius: 4px;
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 45px 0 rgba(42, 42, 42, 0.25);
    background-color: #ffffff;
    color: #151544;
}

.ButtonYellowDiv>a{
    text-decoration: none;
}

@media only screen and (max-width: 1000px) {
    .DirectInvestmentImg01{
        background: url("../../../static/img/DirectInvestment/DirectInvestment011000.jpg") no-repeat center;
        height: 244px;
    }
    .DirectInvestmentPart01{
             margin-top: 0;
    }
}
@media only screen and (max-width: 800px) {
    .DirectInvestmentImg01{
        background: url("../../../static/img/DirectInvestment/DirectInvestment01800.jpg") center;
        height: 157px;
    }
    .DirectInvestmentPart01{
        margin-top: 0;
    }
}
@media only screen and (max-width: 320px) {
    .DirectInvestmentImg01{
        background: url("../../../static/img/DirectInvestment/DirectInvestment01320.jpg") center;

}
}

@media only screen and (max-width: 800px) {
    .DirectInvestmentPart01{
        padding-top: 10px;
    }
}

.DirectInvestmentPart01Grid{
    display: grid;
    grid-template-columns: minmax(400px,552px) minmax(200px,296px);
    grid-column-gap: 40px;
}

@media only screen and (max-width: 800px) {
    .DirectInvestmentPart01Grid{
        grid-template-columns: auto;
    }
}

.DirectInvestmentPart01Title{
    padding-top: 25px;
    padding-bottom: 40px;
    font-weight: 200;
    font-size: 37px;
    line-height: 35px;
}

.DirectInvestmentPart01TitleH2{
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: 200;
    font-size: 32px;
    line-height: 30px;
}


.DirectInvestmentText{
    font-weight: 400;
    font-size: 16px;
    color: black;
    line-height: 27px;
    padding-top: 20px;
    padding-bottom: 42px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}


.DirectInvestmentPart01Text{
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
}

.ButtonYellow{
    padding-left: 15px;
    padding-right: 15px;
}

@media only screen and (max-width: 426px) {
    .DirectInvestmentPart01Img>img{
        width: 100%;
    }

}

.DirectInvestmentPart01GridRightText{
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
}

.DirectInvestmentPart01GridRightImg{
    border-color: gray;
    width: 100%;
    height: 210px;
    border-style: solid;
    background: url("../../../static/img/DirectInvestment/DirectInvestment09.png") center;
    background-size: cover;

}

.ButtonYellowImgStar{
    position: relative;
    top: 5px;
    margin-right: 10px;
}


@media only screen and (max-width: 600px) {
    .DirectInvestmentPart01Title{
        font-size: 30px;
    }
}