.FormSpecialRegistrationText{
    font-weight: 400;
    font-size: 22px;
    color: black;
    line-height: 27px;
    padding-top: 52px;
    padding-bottom: 44px;
    text-align: center;
}

.FormSpecialRegistrationFlex01{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.FormSpecialRegistrationFlex01Element{
    text-align: center;
    min-width: 120px;
    padding-bottom: 35px;
}

@media only screen and (max-width: 500px) {

    .FormSpecialRegistrationText{
        padding-top: 31px;
        padding-bottom: 25px;
    }

    .FormSpecialRegistrationFlex01Element{
        min-width: 90px;
    }
}


.FormSpecialRegistrationImg01{
    background: url('../../static/img/CompanyAdvantages/CompanyAdvantages02.png') center;
    width: 100%;
    height: 510px;
    color: white;
}

.FormSpecialRegistrationImg05Text>span>a,.FormSpecialRegistrationImg05Text>span>a:hover,.FormSpecialRegistrationImg05Text>span>a:visited{
    color: inherit;
}


.FormSpecialRegistrationImg01Grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.FormSpecialRegistrationImg01GridText{
    padding-top: 66px;
}

.FormSpecialRegistrationImg01GridTextTitle{
    font-weight: 500;
    font-size: 19px;
}
.FormSpecialRegistrationImg01GridTextText{
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    padding-bottom: 58px;
    padding-top: 15px;
}

.FormSpecialRegistrationGrid01Grid{
    padding-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 39px;
}

.FormSpecialRegistrationGrid01GridTitle{
    font-weight: 500;
    font-size: 19px;
    color: #161645;
    padding-bottom: 13px;
    padding-top: 28px;
}

.FormSpecialRegistrationGrid01GridText{
    font-weight: 400;
    font-size: 15px;
    color: #161645;
    line-height: 23px;
}



.FormSpecialRegistrationImg02{
    background: url('../../static/img/CompanyAdvantages/CompanyAdvantages03.png') center;
    width: 100%;
    height: 400px;
    color: white;
    margin-top: 40px;
}

.FormSpecialRegistrationImg02GridText{
    padding-top: 47px;
}

.FormSpecialRegistrationImg02Grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.FormSpecialRegistrationImg02GridTextTitle{
    font-weight: 500;
    font-size: 25px;
    padding-bottom: 20px;
}

.FormSpecialRegistrationImg02GridTextItem{
    display: flex;
}

.FormSpecialRegistrationImg02GridTextItemText{
    font-weight: 500;
    font-size: 15px;
    padding-bottom: 20px;
}

.FormSpecialRegistrationImg02GridTextItemImg{
    margin-right: 10px;
}

.FormSpecialRegistrationGrid02Grid{
    padding-top: 40px;
    display: grid;
    grid-template-columns: 1fr  1fr  1fr;
    grid-column-gap: 39px;
}

.FormSpecialRegistrationImg03{
    background: url('../../static/img/CompanyAdvantages/CompanyAdvantages04.png') center;
    width: 100%;
    height: 400px;
    color: white;
    margin-top: 40px;
}

.FormSpecialRegistrationImg03Grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.FormSpecialRegistrationImg04{
    width: 100%;
    height: 400px;
    color: white;
    margin-top: 40px;
    background: url('../../static/img/CompanyAdvantages/CompanyAdvantages05.jpg') no-repeat center;
}

.FormSpecialRegistrationImg05{
    background: url('../../static/img/CompanyAdvantages/CompanyAdvantages06.png') center;
    width: 100%;
    height: 583px;
    color: white;
    margin-top: 40px;
}

.FormSpecialRegistrationImg05Grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 52px;
}

.FormSpecialRegistrationImg05Title{
    text-align: center;
    font-weight: 300;
    font-size: 37px;
    padding-top: 50px;
}

.FormSpecialRegistrationImg05Text{
    text-align: center;
    font-weight: 400;
    font-size: 21px;
    padding-top: 20px;
}

.FormSpecialRegistrationImg05GridText{
    margin-top: 30px;
    width: auto;
}

.FormSpecialRegistrationImg05GridTextItem{
    display: flex;
    padding-bottom: 30px;
}

.FormSpecialRegistrationImg05GridTextItemText{
    font-weight: 400;
    font-size: 21px;
}

@media only screen and (max-width: 700px) {
    .FormSpecialRegistrationGrid01Grid,.FormSpecialRegistrationGrid02Grid{
        grid-template-columns: auto;
        grid-row-gap: 37px;
    }

}

@media only screen and (max-width: 700px) {
    .FormSpecialRegistrationImg01{
        height: auto;
        background: url('../../static/img/CompanyAdvantages/CompanyAdvantages07.png') no-repeat center;
        background-size: cover;
    }
    .FormSpecialRegistrationImg05Title{
        padding-top: 27px;
        font-size: 32px;
    }
    .FormSpecialRegistrationImg05Text{
        font-size: 21px;
    }
    .FormSpecialRegistrationImg01Grid,.FormSpecialRegistrationImg02Grid,.FormSpecialRegistrationImg03Grid,.FormSpecialRegistrationImg04Grid,.FormSpecialRegistrationImg05Grid{
        grid-template-columns: auto;

    }
    .FormSpecialRegistrationImg05GridText{
        grid-template-columns: auto
    }
}

@media only screen and (max-width: 400px) {
    .FormSpecialRegistrationImg01Grid{
        padding-top: 150px;
    }
    .MobiusTrader7MainPhoneTitle{
        font-size: 32px;
    }
}