.HTML404Title{

    font-size: 52px;
}

.HTML404TitleMedium{
    font-weight: 500;
    margin-right: 15px;
}

.HTML404Text{
    font-weight: 500;
    margin-right: 15px;
    font-size: 24px;
    margin-top: 50px;
}