.CompanyAdvantagesText{
    font-weight: 400;
    font-size: 22px;
    color: black;
    line-height: 27px;
    padding-top: 52px;
    text-align: center;
}

.CompanyAdvantagesGrid02GridImg{
    height: 130px;
}

.CompanyAdvantagesFlex01{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.CompanyAdvantagesImg05Text>span{
    margin-left: 5px;
}

.CompanyAdvantagesFlex01Element{
    text-align: center;
    min-width: 120px;
    padding-bottom: 35px;
}

@media only screen and (max-width: 500px) {

    .CompanyAdvantagesText{
        padding-top: 31px;
        padding-bottom: 25px;
    }

    .CompanyAdvantagesFlex01Element{
        min-width: 90px;
    }
}


.CompanyAdvantagesImg01{
    background: url('../../../static/img/CompanyAdvantages/CompanyAdvantages02.png') center no-repeat;
    background-size: cover;
    width: 100%;
    color: white;
}



.CompanyAdvantagesImg01Grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.CompanyAdvantagesImg01GridText{
    padding-top: 66px;
}

.CompanyAdvantagesImg01GridTextTitle{
    font-weight: 500;
    font-size: 19px;
}
.CompanyAdvantagesImg01GridTextText{
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    padding-bottom: 58px;
    padding-top: 15px;
}

.CompanyAdvantagesGrid01Grid{
    padding-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 39px;
}

.CompanyAdvantagesGrid01GridTitle{
    font-weight: 500;
    font-size: 19px;
    color: #161645;
    padding-bottom: 13px;
    padding-top: 28px;
}

.CompanyAdvantagesGrid01GridText{
    font-weight: 400;
    font-size: 15px;
    color: #161645;
    line-height: 23px;
}



.CompanyAdvantagesImg02{
    background: url('../../../static/img/CompanyAdvantages/CompanyAdvantages03.png') center;
    width: 100%;
    color: white;
    margin-top: 40px;
}

.CompanyAdvantagesImg02GridText{
    padding-top: 47px;
    padding-bottom: 30px;
}

.CompanyAdvantagesImg02Grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.CompanyAdvantagesImg02GridTextTitle{
    font-weight: 500;
    font-size: 25px;
    padding-bottom: 20px;
}

.CompanyAdvantagesImg02GridTextItem{
    display: flex;
}

.CompanyAdvantagesImg02GridTextItemText{
    font-weight: 500;
    font-size: 15px;
    padding-bottom: 20px;
}

.CompanyAdvantagesImg02GridTextItemImg{
    margin-right: 10px;
}

.CompanyAdvantagesGrid02Grid{
    padding-top: 40px;
    display: grid;
    grid-template-columns: 1fr  1fr  1fr;
    grid-column-gap: 39px;
}

.CompanyAdvantagesImg03{
    background: url('../../../static/img/CompanyAdvantages/CompanyAdvantages04.png') center top;
    width: 100%;
    color: white;
    margin-top: 40px;
}

.CompanyAdvantagesImg03Grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 30px;
}

.CompanyAdvantagesImg04{
    background: black url('../../../static/img/CompanyAdvantages/CompanyAdvantages05.jpg') no-repeat center;
    width: 100%;
    color: white;
    margin-top: 40px;
}

.CompanyAdvantagesImg05{
    background: url('../../../static/img/CompanyAdvantages/CompanyAdvantages06.png') center;
    width: 100%;
    height: 583px;
    color: white;
    margin-top: 40px;
}

.CompanyAdvantagesImg05Grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 52px;
}

.CompanyAdvantagesImg05Title{
    text-align: center;
    font-weight: 300;
    font-size: 37px;
    padding-top: 50px;
}

.CompanyAdvantagesImg05Text{
    text-align: center;
    font-weight: 400;
    font-size: 21px;
    padding-top: 20px;
}

.CompanyAdvantagesImg05GridTextItem{
    display: flex;
    padding-bottom: 30px;
}

.CompanyAdvantagesImg05GridTextItemText{
    font-weight: 400;
    font-size: 21px;
}

@media only screen and (max-width: 700px) {
    .CompanyAdvantagesGrid01Grid,.CompanyAdvantagesGrid02Grid{
        grid-template-columns: auto;
        grid-row-gap: 37px;
    }

}

@media only screen and (max-width: 700px) {
    .CompanyAdvantagesImg01{
        height: auto;
        background: url('../../../static/img/CompanyAdvantages/CompanyAdvantages07.png') no-repeat center;
        background-size: cover;
    }
    .CompanyAdvantagesImg05Title{
        padding-top: 27px;
        font-size: 32px;
    }
    .CompanyAdvantagesImg05Text{
        font-size: 21px;
    }
    .CompanyAdvantagesImg01Grid,.CompanyAdvantagesImg02Grid,.CompanyAdvantagesImg03Grid,.CompanyAdvantagesImg04Grid,.CompanyAdvantagesImg05Grid{
        grid-template-columns: auto;

    }
    .CompanyAdvantagesImg05GridText{
        display: none;
    }
    .CompanyAdvantagesImg05GridText{
        grid-template-columns: auto
    }
}

@media only screen and (max-width: 400px) {
    .CompanyAdvantagesImg01Grid{
        padding-top: 150px;
    }
}
.CompanyAdvantagesFlex01>.TopEarnTopRightCurrenciesElement{
    margin-bottom: 36px;
}

.CompanyAdvantagesFlex01>div>.TopEarnTopRightCurrenciesElementText{
    color: black;

}