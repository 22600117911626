

.HeaderAndMenu{
    width: 100%;
    background-image: linear-gradient(84deg, #1d0d56, #192b71);
    position: relative;
}

.HeaderAll{
    margin: auto;
    display: grid;
    grid-template-columns: 153px auto;
}

.HeaderAllTitle{
    padding-top: 20px;
    position: relative;
    padding-left: 29px;
}

.HeaderAllName{
    padding-top: 10px;
}

.HeaderAllQuotesButtons{
    width: 30px;
    padding-top: 11px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-right: 1px rgba(255,255,255,0.1) solid;
    color: rgba(255, 255, 255, 0.31);
    font-weight: 700;
    font-size: 15px;
    position: relative;
    z-index: 5;
}

.HeaderAllQuotes{
    display: flex;
    box-shadow: inset 1px -1px 0 0 rgba(255, 255, 255, 0.5);
    background-color: rgba(250,236,164,0.15);

}
.HeaderAllQuotesIn {
    padding-top: 11px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-around;
    width: 100%
}
.HeaderAllQuotesInElement{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100px;
}

.HeaderAllQuotesInElement:first-of-type{
    padding-left: 10px;
}

.HeaderAllQuotesInElementTitle{
    font-weight: 700;
    font-size: 13px;
    color: white;
}

.HeaderAllQuotesInElementText{
    font-weight: 400;
    font-size: 13px;
    color: white;
}
.HeaderAllQuotesInElementTextActive{
    font-weight: 400;
    font-size: 13px;
    color: #ff6b6b;
}

@media only screen and (max-width: 1620px) {
    .HeaderAllQuotesInElement:nth-of-type(14){
        display: none;
    }
}
@media only screen and (max-width: 1520px) {
    .HeaderAllQuotesInElement:nth-of-type(13){
        display: none;
    }
}
@media only screen and (max-width: 1420px) {
    .HeaderAllQuotesInElement:nth-of-type(12){
        display: none;
    }
}

@media only screen and (max-width: 1320px) {
    .HeaderAllQuotesInElement:nth-of-type(11){
        display: none;
    }
}

@media only screen and (max-width: 1220px) {
    .HeaderAllQuotesInElement:nth-of-type(10){
        display: none;
    }
}

@media only screen and (max-width: 1120px) {
    .HeaderAllQuotesInElement:nth-of-type(9){
        display: none;
    }
}
@media only screen and (max-width: 1020px) {
    .HeaderAllQuotesInElement:nth-of-type(8){
        display: none;
    }
}
@media only screen and (max-width: 920px) {
    .HeaderAllQuotesInElement:nth-of-type(7){
        display: none;
    }
}
@media only screen and (max-width: 820px) {
    .HeaderAllQuotesInElement:nth-of-type(6){
        display: none;
    }
}
@media only screen and (max-width: 720px) {
    .HeaderAllQuotesInElement:nth-of-type(5){
        display: none;
    }
}
@media only screen and (max-width: 620px) {
    .HeaderAllQuotesInElement:nth-of-type(4){
        display: none;
    }
}
@media only screen and (max-width: 520px) {
    .HeaderAllQuotes{
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .HeaderAllQuotes{
        display: none;
    }
}



