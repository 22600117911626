.cab_in{
    padding: 0 28px 28px 28px;
    position: fixed;
    z-index: 500;
    top: 100px;
    right: calc(50% - 200px);
    margin: auto;
    height : 380px;
    width : 330px;
    background-color: white;
    display: block;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    justify-content: space-between;
    align-items: center;}

.SubMenuTopPage{
    transition-delay: 300ms;
    position: relative;
    left: -1px;
    width: 100%;
    margin:auto;
    max-width: 1144px;
    border-bottom: 1px solid #ced4d9;
    border-left: 1px solid #ced4d9;
    border-right: 1px solid #ced4d9;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 2;
}

.SubMenuClassName{
    border-left: 1px solid white;
    border-right: 1px solid white;
}

.MainMenuTerminalMenuMobiusLogo{
    position: relative;
    top: 13px;
}

@media only screen and (max-width: 800px) {
    .SubMenuTopPage{
        border: none;
    }
}

.HeaderAllRightLK{
    display: flex;
    align-items: center;
    color: white;
    border: 2px white solid;
    border-radius: 4px;
    height: 36px;
    cursor: pointer;
}

.HeaderAllRightLK>img{
    padding-left: 7px;
    padding-right: 7px;
}

.EnterCabinetActive>.VisibleAllDark{
    z-index: 500;
}

.iFrameCabinetLinks{
    display: flex;
    justify-content: space-around;
}
.iFrameCabinetLinksElement>a{
    text-decoration: none;
    color: #4a90e2;
}

.HeaderAllRightLKIn>img{
    padding-right: 5px;
}
.HeaderAllRightLKIn{
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
}