.MainMenu{
    height: 79px;
    position: relative;
    z-index: 15;
}

.MainMenuSubMenu{
    border-radius: 3px;
    box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    position: absolute;
    z-index: 50;
    top: 50px;
    left: -15px;
    padding: 16px 0 16px 0;
    display: none;
}

.MainMenuElement {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
    position: relative;
    height: 50px;
}

.MainMenu>.ButtonOut{
    align-items: center;
}

.MainMenuElement:hover>div>.MainMenuSubMenu{
    display: flex;
    flex-direction: column;

}

.MainMenuSubMenuElement>a{
    color: black;
    text-decoration: none;
    font-size: 16px;
}

.MainMenuSubMenuElement{
    padding: 12px 24px 12px 24px;
}
.MainMenuSubMenuElement:hover{
    background-color: #ffc920;
    font-weight: 700;
}

.MainMenuTerminalMenu>a{
    text-decoration: none;
}
.MainMenuTerminalMenu>a>div{
    padding: 12px 24px 12px 24px;
    display: flex;
    align-items: center;
    color: black;
}

.MainMenuTerminalMenu>a>.MainMenuSubMenuOutActive{
    display: none;
}

.MainMenuTerminalMenu>a>div>img{
    padding-right: 15px;
}

.MainMenuTerminalMenu>a>div:hover{
    background-color: #ffc920;
    font-weight: 700;
}

.MainMenuTerminalMenu>a:hover>.MainMenuSubMenuOutActive{
    display: flex;
}
.MainMenuTerminalMenu>a:hover>.MainMenuSubMenuOutActiveNot{
    display: none;
}

@media only screen and (max-width: 1000px) {
    .MainMenu>.MainMenuElement{
        display: none;
    }
}