.CompanyContactsGrid{
    margin-top: 81px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 54px;
    padding-bottom: 40px;
}

.CompanyContactsGridLeftWhite{
    border-radius: 4px;
    box-shadow: 0 10px 45px 0 #e0e6f4;
    background-color: #ffffff;
    display: grid;
    grid-column: auto;

}

.CompanyContactsGridLeftWhiteElement{
    display: grid;
    grid-template-columns: auto;
    text-align: center;
    padding-top: 37px;

}

.CompanyContactsGridLeftAddressElement{
    display: grid;
    grid-template-columns: auto;
    text-align: center;
    padding-top: 49px;
}

.CompanyContactsGridLeftAddress{
    grid-column: 1;
    grid-row: 2;
}



.CompanyContactsGridLeftWhiteElementText{
    font-weight: 400;
    font-size: 27px;
    color: black;
    line-height: 50px;
    padding-top: 5px;
    padding-bottom: 49px;
}

.CompanyContactsGridLeftAddressText{
    font-weight: 400;
    font-size: 21px;
    color: black;
    line-height: 30px;
    padding-top: 5px;
}

.CompanyContactsGridLeftWhiteElementBlueText{
    color: #4a90e2;
    text-decoration: underline;
}

.CompanyContactsGridFormTitle{
    font-weight: 200;
    font-size: 32px;
    color: #151544;
    line-height: 35px;
    /*text-align: center;*/
    margin-bottom: 30px;
}

.CompanyContactsGridForm{
    grid-row: 1/3;
    display: grid;
    grid-template-rows: auto auto 60px;
}

@media only screen and (max-width: 800px) {
    .CompanyContactsGridLeftWhite{
        grid-column: 1/3;
        grid-template-columns: 1fr 1fr;
    }
    .CompanyContactsGridForm{
        grid-row: 2;
        grid-column: 1/3;
        padding-top: 30px;
    }
    .CompanyContactsGridLeftAddress{
        grid-row: 3;
        grid-column: 1/3;
    }

}

@media only screen and (max-width: 600px) {
    .CompanyContactsGridLeftWhite{
        grid-template-columns: auto;
    }

}

.CompanyContactsGridRightInsert{
    display: flex;
    flex-direction: column;
}

.CompanyContactsGridRightInsert>input{
    height: 40px;
    width: calc(100% - 24px);
    border: 1px solid #5c5c5c;
    padding-left: 20px;
    font-size: 19px;
}

.CompanyContactsGridRightInsert>textarea{
    margin-top: 20px;
    height: 180px;
    width: calc(100% - 24px);
    border: 1px solid #5c5c5c;
    padding-left: 20px;
    padding-top: 10px;
    font-size: 19px;
}

.TraderFeedbackError{
    font-weight: 400;
    font-size: 14px;
    color: #ff5151;
    padding-top: 8px;
    height: 20px;
}

.CompanyContactsGridRightButton{
    margin-top: 20px;
}

.CompanyContactsGridForm>.isNoVisible{
    display: none;
}

.TraderFeedbackSuccess{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TraderFeedbackSuccess>div{
    text-align: center;
}

.TraderFeedbackSuccessTitle{
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 10px;
}
.TraderFeedbackSuccessText{
    font-size: 18px;
    font-weight: 400;
}
.TraderFeedbackSuccessText>a{
    padding-left: 5px;
}

