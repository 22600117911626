.TraderClientOpenMain{
    margin-top: 40px;
}

.TraderClientOpenMain{
    /*display: grid;*/
    /*grid-template-columns: auto auto;*/
    /*grid-column-gap: 30px;*/
}

.TraderClientOpenMain>.StandartNotVisible{
    display: none;
}

.TraderRegistrationGridRegistrationFormOut{
    display: flex;
    justify-content: space-between;
}

.TraderRegistrationGridRegistrationFormElementTopTitleOut{
    display: flex;
    width: 388px;
    justify-content: space-between;
    align-items: center;
}

.OpenRealDocuments{
    border-radius: 4px;
    box-shadow: 1.5px 2.6px 15px 0 rgba(46, 81, 102, 0.1);
    border: solid 2px #8cc845;
    width: 145px;
    height: auto;
    padding: 19px;
}

.OpenRealDocumentsElement{
    display: flex;
}

.OpenRealDocumentsTitle{
    font-size: 15px;
    color: black;
    font-weight: 700;
}

.OpenRealDocumentsElement{
    margin-top: 18px;
}

.OpenRealDocumentsElementImg{
    margin-right: 12px;
    margin-top: 4px;
}

.OpenRealDocumentsElementText>a{
    font-size: 13px;
    color: #7bba31;
    font-weight: 400;
}

.TraderRegistration{
    max-width: 730px;
    margin: auto;
}

@media only screen and (max-width: 770px) {
    .TraderRegistration{
        margin-right: 20px;
        margin-left: 20px;
    }
}





.TraderRegistration>div>.ButtonYellow{
    padding-right: 30px;
    padding-left: 30px;
}

.TraderRegistrationGridRegistrationFormElementInputInput{
    width: 368px;
    height: 56px;
    border-radius: 4px;
    border: solid 1px #5c5c5c;
    font-size: 19px;
    padding-left: 20px;
}

.TraderRegistrationGridRegistrationFormElementTopTitle{
    font-size: 19px;
    padding-bottom: 5px;
    padding-top: 16px;
}

.TraderRegistrationGridRegistrationFormElementInputInput::placeholder{
    font-size: 19px;
    position: relative;
}


.TraderRegistrationGridPassword{
    position: relative;
    width: 368px;
}

.PasswordNoView>.TraderRegistrationGridRegistrationFormElementInputPassword>.TraderRegistrationGridPassword>.TraderRegistrationPasswordOpen{
    display: none;
}
.PasswordView>.TraderRegistrationGridRegistrationFormElementInputPassword>.TraderRegistrationGridPassword>.TraderRegistrationPasswordClose{
    display: none;
}

.TraderRegistrationPasswordOpen,.TraderRegistrationPasswordClose{
    position: absolute;
    z-index: 5;
    right: 1px;
    top: 16px;
    cursor: pointer;
}

.ButtonTraderRegistrationPassword{
    font-size: 11px;
    color: #09518e;
    margin-left: 50px;
    cursor: pointer;
    position: relative;
    top: 1px;
}

.TraderRegistrationGridRegistrationFormElementInputPassword{
    display: flex;
    align-items: center;
}

.TraderRegistrationButtomText{
    margin-top: 23px;
    margin-bottom: 25px;
}

.TraderRegistrationGridRegistrationForm>.ButtonYellow{
    width: 345px;
}


.TraderRegistrationGridRegistrationFormElementTopTextError{
    margin-top: 6px;
    font-size: 14px;
    display: none;
    font-weight: 500;
}

.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementInput>.TraderRegistrationGridRegistrationFormElementTopTextError,.TraderRegistrationGridRegistrationFormElementError>.TraderRegistrationGridRegistrationFormElementInputPassword>.TraderRegistrationGridPassword>.TraderRegistrationGridRegistrationFormElementTopTextError {
    display: block;
    color: #da343d;
}

.TraderRegistrationGridRegistrationFormElementTopTextErrorPassword{
    display: block;
}

.TraderRegistrationSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 730px;
    margin: auto;
}

.TraderRegistrationSuccess>img{
    padding-top: 10px;
    padding-bottom: 26px;
    margin: auto;
}

.TraderRegistrationSuccessMain{
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 10px 45px 0 #e0e6f4;
    border: solid 1px #eeeeee;
}

.TraderRegistrationSuccessTitle{
    font-weight: 900;
    font-size: 40px;
    color: #ff3e3e;
    text-align: center;
    margin-top: 42px;
    margin-bottom: 20px;
}

.TraderRegistrationSuccessText{
    font-size: 21px;
    text-align: center;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e6f4;
}

.TraderRegistrationSuccessGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e6f4;
}

.TraderRegistrationSuccessAllInfoElementText{
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 5px;
    margin-top: 25px;
}

.TraderRegistrationSuccessAllInfoElementInfo{
    font-size: 17px;
    margin-bottom: 5px;
    margin-top: 25px;}

.TraderRegistrationSuccessGrid>a{
    text-decoration: none;
    margin-top: 40px;
}

.TraderRegistrationSuccessGridButton{
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
}
.TraderRegistrationSuccessGridButton>a{
    /*padding-top: 15px;*/
    /*padding-bottom: 15px;*/
    text-decoration: none;
    width: 100%;
    font-size: 17px;
    line-height: 50px;
}

.TraderRegistrationSuccessGridButton>a>img{
    position: relative;
    top: 4px;
    left: 10px;
    margin-left: 40px;
}
.TraderRegistrationSuccessGridButtonFirst>a>img{
    top: 7px;
    margin-left: 15px;
}

.TraderRegistrationSuccessGridButton>.ButtonTransparency{
    border: 2px solid black;
}


.TraderRegistrationSuccessGrid>div>.ButtonTransparency{
    color: black;
}

@media only screen and (max-width: 700px) {
    .TraderRegistrationGridRegistrationFormOut{
        display: block;
    }

    .OpenRealDocumentsOut{
        display: none;
        margin-top: 30px;
        width: 348px;
    }
    .OpenRealDocuments{
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .TraderRegistrationSuccessGrid{
        display: block;
    }
    .TraderRegistrationSuccessGrid>div>.ButtonTransparency{
        margin-top: 20px;
    }
    .TraderRegistrationSuccessGrid>div>a{
        margin-top: 20px;
        width: 250px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .TraderRegistrationSuccessGridButton{
        justify-content: center;
    }
    .TraderRegistrationSuccessGrid>.TraderRegistrationSuccessGridButtonSecond>a{
        width: 246px;
    }

    .TraderRegistrationSuccessGridButton{
        margin-top: 20px;
    }
}

@media only screen and (max-width: 450px) {
    .TraderRegistrationGridRegistrationFormElementInputInput{
        width: 90%;
    }
    .OpenRealDocumentsOut{
        width: calc(90% - 16px);
    }
    .TraderRegistrationGridRegistrationFormElementTopTitleOut{
        width: 90%;
    }
    .TraderRegistrationPasswordClose,.TraderRegistrationPasswordOpen{
        right: 20px;
    }
    .TraderRegistrationSuccessGrid{
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media only screen and (max-width: 700px) {
    .TraderRegistration>.StandartPhone700 {
        margin-top: 0;
        margin-bottom: 40px;
        display: block;
    }
}

.TraderRegistrationSuccessTextPrint>img{
    padding-right: 10px;
}
.TraderRegistrationSuccessTextPrint{
    margin-left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}


@media print {
    footer,header,.MainMenu,.MainMenuOut,.IfYouHaveQuestions,.OpenRealDocuments,.TraderRegistrationSuccessGridButton,.ButtonTraderRegistration{
        display: none;
    }
}