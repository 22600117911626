.MainMenu{
    display: flex;
    justify-content: space-around;
    grid-column: 1/span 2;
    grid-row: 2;
    align-items: center;
}

.MainMenuSubMenuOut{
    visibility: hidden;
}

.MainMenuElement:hover{
    color: #ffc920;
}

.MainMenuElement:hover>.MainMenuSubMenuOut{
    visibility: visible;
    transition-delay: 300ms;
    z-index: 3;
}

.MainMenu>div>div>a{
    color: black;
}

