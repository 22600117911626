.HowBitcoinWorksText{
    font-weight: 400;
    font-size: 16px;
    color: black;
    line-height: 27px;
    padding-top: 27px;
    padding-bottom: 42px;
    text-align: center;
}

.HowBitcoinWorksImg01{
    width: 100%;
    height: 500px;
    background: url("../../../static/img/HowBitcoinWorks/HowBitcoinWorks01.png") center;
}

.HowBitcoinWorksPart01Out{
    position: relative;
    top: -161px;
    border-radius: 4px;
}

.HowBitcoinWorksPart01{
    position: relative;

    background-color: rgba(256,256,256,0.8);
    box-shadow: 0 10px 45px 0 rgba(42, 42, 42, 0.25);
    display: grid;
    grid-template-columns: 522px 356px;
    grid-column-gap: 40px;
    padding-left: 40px;
    z-index: 10;
    backdrop-filter: blur(10px);
}

.HowBitcoinWorksPart01TopBackground{
    position: absolute;
    background: url("../../../static/img/HowBitcoinWorks/HowBitcoinWorks01.png") bottom;
    filter: blur(10px);
    z-index: 5;
    width: 100%;
    height: 161px;
    max-width: 1024px;
}

/*@media only screen and (max-width: 600px) {*/
/*    .HowBitcoinWorksPart01Left{*/
/*        padding-left: 15px;*/
/*        padding-right: 15px;*/
/*    }*/
/*}*/
@media only screen and (max-width: 800px) {
    .HowBitcoinWorksPart01{
        padding-top: 10px;
    }
}


.HowBitcoinWorksPart01Left{
    padding-bottom: 20px;
}

.HowBitcoinWorksPart01>.HowBitcoinWorksPart01Text{
    padding-bottom:  20px;
}

.HowBitcoinWorksPart02>.StandartPageIn>.HowBitcoinWorksPart01Text{
    padding-bottom:  20px;
}


.HowBitcoinWorksPart01Title{
    font-weight: 200;
    font-size: 37px;
    color: #151544;
    line-height: 35px;
    padding-top: 25px;
    padding-bottom: 40px;
}
.HowBitcoinWorksPart01RightTitle{
    font-weight: 200;
    font-size: 32px;
    color: #151544;
    line-height: 35px;
    padding-top: 25px;
    padding-bottom: 40px;
}

.HowBitcoinWorksPart01RightText{
    font-weight: 500;
    font-size: 15px;
    color: #151544;
    line-height: 25px;
}
.HowBitcoinWorksPart01Text{
    position: relative;
    font-weight: 400;
    font-size: 17px;
    color: #151544;
    line-height: 25px;
}

.HowBitcoinWorksImg03>img{
    width: 100%;
    text-align: center;
    margin: auto;
}

.HowBitcoinWorksImg02>img{
    width: 100%;
    margin: auto;
}

.HowBitcoinWorksPart01RightElement{
    display: grid;
    grid-template-columns: 52px auto;
    grid-column-gap: 15px;
    padding-bottom: 28px;
}


.HowBitcoinWorksPart01RightElementTextTitle{
    font-weight: 500;
    font-size: 15px;
    color: #151544;
    line-height: 25px;
}

.HowBitcoinWorksPart01RightElementTextInfo{
    font-weight: 400;
    font-size: 15px;
    color: #151544;
    line-height: 25px;
}

.HowBitcoinWorksPart02{
    margin-top: -324px;
    position: relative;
    z-index: -2;
    height: 438px;
    background-image: linear-gradient(78deg, #ff4040, #ff713a);
    padding-bottom: 50px;
}

.HowBitcoinWorksPart02Title{
    font-weight: 200;
    font-size: 37px;
    color: white;
    line-height: 35px;
    padding-top: 220px;
}

.HowBitcoinWorksPart02Text{
    font-weight: 400;
    font-size: 17px;
    color: white;
    line-height: 25px;
    padding-top: 10px;
    margin-top: 30px;
}

.HowBitcoinWorksPart02>div>div{
    text-align: center;
}
.HowBitcoinWorksPart02>div>.ButtonOut{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.HowBitcoinWorksPart03{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 872px;
    background-color: black;
    color: white;
}

.HowBitcoinWorksPart03Left{
    background: url("../../../static/img/HowBitcoinWorks/HowBitcoinWorks05.jpg") no-repeat top;
    display: flex;
    justify-content: flex-end;

}
.HowBitcoinWorksPart03LeftIn{
    max-width: 433px;
    padding-right: 38px;

}

.HowBitcoinWorksPart03Right{
    max-width: 433px;
    background: #0a1b4b url("../../../static/img/HowBitcoinWorks/HowBitcoinWorks04.png") no-repeat top;
    background-size: contain;
    padding-left: 49px;
    padding-right: 38px;
    margin-bottom: 30px;
}

.HowBitcoinWorksPart03Title{
    font-weight: 200;
    font-size: 37px;
    color: white;
    line-height: 43px;
    padding-top: 325px;
    padding-bottom: 20px;
}
.HowBitcoinWorksPart03Text{
    font-weight: 400;
    font-size: 17px;
    color: white;
    line-height: 30px;
}

.HowBitcoinWorksPart02Button{
    margin-top: 20px;
}

@media only screen and (max-width: 1000px) {
    .HowBitcoinWorksPart01{
        grid-template-columns: 2fr 1fr;
        padding-right: 15px;
    }
    .HowBitcoinWorksPart03{
        display: block;
        height: auto;
    }
    .HowBitcoinWorksPart03LeftIn,.HowBitcoinWorksPart03Right{
        max-width: unset;
        padding-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .HowBitcoinWorksText{
        padding-left: 15px;
        padding-right: 15px;
    }
    .HowBitcoinWorksPart02{

        margin-top: -162px;
        height: auto;
    }
    .HowBitcoinWorksPart02Title{
        padding-top: 50px;
    }

}

@media only screen and (max-width: 600px) {
    .HowBitcoinWorksPart01RightTitle{
        font-size: 30px;
    }

    .HowBitcoinWorksPart01{
        display: block;
        padding-left: 0;
        padding-right: 0;
    }
    .HowBitcoinWorksPart01>.HowBitcoinWorksPart01Left,.HowBitcoinWorksPart01>.HowBitcoinWorksPart01Left{
        display: block;
        padding-left: 15px;
        padding-right: 15px;
    }
    .HowBitcoinWorksPart01Text>p,.HowBitcoinWorksPart01Title,.HowBitcoinWorksPart01Right{
        padding-right: 15px;
        padding-left: 15px;
    }
    .HowBitcoinWorksPart02Title{
        padding-top: 40px;
    }


}

.HowBitcoinWorksPart01RightElement>.HowBitcoinWorksPart01RightElementImg>img{
    position: relative;
    top: 4px;
}