.BastProduct{
    width: 100%;
    margin-bottom: 46px;
}

.BastProductIn{
    max-width: 1040px;
    margin: auto;
}

.BastProductTitle{
    font-size: 37px;
    font-weight: 300;
    color: #151544;
    text-align: center;
    padding: 40px 30px 50px;
}
@media only screen and (max-width: 700px) {
    .BastProductTitle{
        font-size: 25px;
        padding-top: 0;
    }
}


.BastProductFlex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.BastProductFlexElement{
    width: 205px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;

}

.BastProductFlexElementTitle{
    font-size: 15px;
    font-weight: 700;
    color: #151544;
}
.BastProductFlexElementText{
    font-size: 15px;
    font-weight: 400;
    color: #151544;
}

.BastProductIn>.ButtonOut>.AdvantageBlackInGridRightButton{
    top: auto;
    left: auto;
    margin: auto;
}

.AdvantageBlackInGridRightButton>a{
    text-decoration: none;
    color: inherit;
}
