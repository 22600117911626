.MobiusTrader7{
    width: 100%;
}

.MobiusTrader7In{
    max-width: 1040px;
    margin: auto;
    padding-right: 20px;
    padding-left: 20px;
}

.MobiusTrader7TopImg{
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.MobiusTrader7TopTitle{
    font-size: 90px;
    font-weight: 400;
    color: #151544;
    padding-top: 15px;
    text-align: center;
}

.MobiusTrader7TopTerminals{
    display: flex;
    margin: auto;
    margin-top: 40px;
    max-width: 443px;
    justify-content: space-between;
}

.MobiusTrader7TopTerminals>a>div{
    background-repeat: no-repeat;
    cursor: pointer;
}

.MobiusTrader7TopTerminalsElement03{
    background-image: url("../../static/img/IndexMain/Android.svg");
    width: 33px;
    height: 34px;
}
.MobiusTrader7TopTerminalsElement03:hover{
    background-image: url("../../static/img/IndexMain/Android_active.svg");
}
.MobiusTrader7TopTerminalsElement01{
    background-image: url("../../static/img/IndexMain/Windows.svg");
    width: 33px;
    height: 34px;
}
.MobiusTrader7TopTerminalsElement01:hover{
    background-image: url("../../static/img/IndexMain/Windows_active.svg");
}
.MobiusTrader7TopTerminalsElement02{
    background-image: url("../../static/img/IndexMain/Apple.svg");
    width: 33px;
    height: 34px;
}
.MobiusTrader7TopTerminalsElement02:hover{
    background-image: url("../../static/img/IndexMain/Apple_active.svg");
}
.MobiusTrader7TopTerminalsElement04{
    background-image: url("../../static/img/IndexMain/Linux.svg");
    width: 33px;
    height: 34px;
}
.MobiusTrader7TopTerminalsElement04:hover{
    background-image: url("../../static/img/IndexMain/Linux__active.svg");
}
.MobiusTrader7TopTerminalsElement05{
    background-image: url("../../static/img/IndexMain/MacOS.svg");
    width: 33px;
    height: 34px;
}
.MobiusTrader7TopTerminalsElement05:hover{
    background-image: url("../../static/img/IndexMain/MacOS_active.svg");
}
.MobiusTrader7TopTerminalsElement06{
    background-image: url("../../static/img/IndexMain/WebTerminal.svg");
    width: 33px;
    height: 34px;
}
.MobiusTrader7TopTerminalsElement06:hover{
    background-image: url("../../static/img/IndexMain/WebTerminal_active.svg");
}

.MobiusTrader7Main{
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
}

.MobiusTrader7MainLeftTitle{
    padding-top: 60px;
    font-size: 37px;
    font-weight: 200;
    color: #151544;
}

.MobiusTrader7MainPhoneTitle{
    padding-top: 60px;
    font-size: 37px;
    font-weight: 200;
    color: #151544;
    text-align: center;
    grid-column: 1/span 2;
}

.MobiusTrader7MainLeftGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 50px;
}

.MobiusTrader7MainLeftGridElement{
    display: grid;
    grid-template-rows: 114px auto auto;
    padding-bottom: 40px;
}

.MobiusTrader7MainLeftGridElementTitle{
    font-size: 15px;
    font-weight: 700;
    color: #151544;
}
.MobiusTrader7MainLeftGridElementText{
    font-size: 15px;
    font-weight: 400;
    color: #151544;
}

.MobiusTrader7TopTerminals{
    flex-wrap: wrap;
}

.MobiusTrader7MainPhoneTitle{
    display: none;
}

@media only screen and (max-width: 850px) {
    .MobiusTrader7MainPhoneTitle{
        display: block;
    }
    .MobiusTrader7MainLeftTitle{
        display: none;
    }
    .MobiusTrader7Main{
    }
    .MobiusTrader7MainLeftGrid{
        display: flex;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 750px) {
    .MobiusTrader7TopTitle{
        font-size: 35px;
    }
    .MobiusTrader7MainLeftTitle{
        font-size: 23px;
    }
}

.MobiusTrader7MainRight{
    background: url("../../static/img/IndexMain/IndexMobiusTrader7.png") no-repeat;
    background-size: cover;
}

@media only screen and (max-width: 600px) {
    .MobiusTrader7Main{
        display: block;
    }
}