.AdvantageBlack{
    width: 100%;
    background-color: black;
}

.AdvantageBlackIn{
    width: 1040px;
    margin: auto;
    height: 721px;
}

.AdvantageBlackInGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    margin-bottom: 30px;
}

AdvantageBlackInGridLeft{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.AdvantageBlackInGridLeftTitle{
    font-size: 37px;
    font-weight: 300;
    line-height: 43px;
    color: #ffffff;
    width: 400px;
    padding-left: 40px;
    position: relative;
    top: -40px;
}
.AdvantageBlackInGridLeftText{
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    color: #ffffff;
    width: 400px;
    padding-left: 40px;
    position: relative;
    top: -40px;
}

.AdvantageBlackInGridLeftButton{
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    padding: 10px 20px 10px 20px;
    border: solid white 2px;
    border-radius: 4px;
    position: relative;
    top: -40px;
    left: 40px;
}

.AdvantageBlackInGridButtonOut{
    display: flex;
    position: relative;
    top: 20px;
}

.AdvantageBlackInGridButtonOut>.AdvantageBlackInGridLeftButton>a{
    text-decoration: none;
    color: inherit;
}

.AdvantageBlackInGridButtonOut>a{
    text-decoration: none;
    color: inherit;
}


.AdvantageBlackInGridRight{
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    background-color: #aeffe8;
}

.AdvantageBlackInGridRightTitle{
    font-size: 37px;
    font-weight: 300;
    line-height: 43px;
    color: black;
    width: 400px;
    padding-left: 40px;

}
.AdvantageBlackInGridRightText{
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    color: black;
    width: 400px;
    padding-left: 40px;
    position: relative;
    top: -40px;
}



.AdvantageBlackInGridLeftIMG{
    background: url("../../static/img/IndexMain/BAAL_index_4.jpg") center;
    height: 307px;
}
.AdvantageBlackInGridRightIMG{
    background: url("../../static/img/IndexMain/BAAL_INDEX_code.png") center;
    width: 100%;
    height: 322px;
    position: relative;
    top: -16px;
}

@media only screen and (max-width: 1040px) {
    .AdvantageBlackIn{
        width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    .AdvantageBlackInGrid>div>div{
        width: unset;
        padding-left: 19px;
        padding-right: 19px;
    }
}

@media only screen and (max-width: 768px) {
    .AdvantageBlackInGrid{
        display: block;
    }
    .AdvantageBlackIn{
        height: unset;
    }
    .AdvantageBlackInGrid>div{
        /*height: 787px;*/
    }
    .AdvantageBlackInGridRightTitle{
        font-size: 23px;
    }
}
