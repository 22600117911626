.PhoneMenu{
    display: none;
}

@media only screen and (max-width: 1000px) {
    .PhoneMenu{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 40px;
    }
}

.PhoneMenuFixed{
    position: fixed;
    top: 0;
    right: 0;
    padding: 16px 21px 90px 21px;
    width: 420px;
    background-image: linear-gradient(26deg,#1d0d56 4%,#192b71 97%);
    z-index: 30;
}

.PhoneMenuClose{
    cursor: pointer;
    padding-right: 15px;
}

.PhoneMenuTop{
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PhoneMenuElement{
    padding-bottom: 40px;
    font-size: 14px;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.PhoneMenuElementActive{
    margin-bottom: 40px;
}

.PhoneMenuElementActive>div>.PhoneMenuSubMenu{
    display: block;
}

.PhoneMenuElementActive>span{
    color: #ffc920;
    border-bottom: 5px solid #ffc920;
    font-weight: 700;
    margin-bottom: 30px;
    width: auto;
}

.HeaderAllName{
    cursor: pointer;
}

@media only screen and (max-width: 475px) {
    .PhoneMenuFixed{
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
    .PhoneMenuFixed>div{
        padding-left: 10px;
    }
    .PhoneMenuElementActive>div>.PhoneMenuSubMenu{
        left: 130px;
    }
}