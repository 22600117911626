.PhoneMenuSubMenu{
    position: absolute;
    top: 120px;
    left: 180px;
    max-width: 60px;
    display: none;
}

.PhoneMenuSubMenuElement>a{
    color: white;
    text-decoration: none;
}

.PhoneMenuSubMenuElement{
    padding-bottom: 30px;
}

@media only screen and (max-width: 375px) {
    .PhoneMenuSubMenuElement>a{
        font-size: 12px;
    }
}
