body{
    margin: 0;
    font-family: Geometria, sans-serif ;
}

.ButtonYellowDiv{
    display: flex;
    justify-content: center;
    padding-bottom: 39px;
}

.StandartMargin{
    margin: auto;
}

.ButtonOut{
    display: flex;
}

.isVisible{
    display: block;
}
.isNoVisible{
    display: none;
}

.StandartPage{
    width: 100%;
    margin: auto;
    max-width: 1024px;
}

div, input, button, textarea{
    outline:none;
}

.TextYellow{
    color: #ffc920;
    text-decoration: underline;
}
.TextYellow>a{
    color: inherit;
}
.StandartTitle{
    font-weight: 300;
    font-size: 70px;
    color: black;
    /*line-height: 50px;*/
    padding-top: 47px;
    text-align: center;
}

.StandartPageIn{
    padding-left: 31px;
    padding-right: 31px;
}

.StandartPageInRight{
    padding-right: 31px;
}

@media only screen and (max-width: 1100px) {
    .StandartIn>div{
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media only screen and (max-width: 800px) {
    .StandartTitle{
        font-size: 60px;
        padding-top: 25px;
    }
    .StandartPageIn{
        padding-left: 20px;
        padding-right: 20px;
    }

    .StandartPageInRight{
        padding-right: 20px;
    }

}

.StandartNotVisible{
    display: none;
}

@media only screen and (max-width: 600px) {
    .StandartTitle{
        font-size: 40px;
    }
}

@media only screen and (max-width: 400px) {
    .StandartTitle{
        font-size: 35px;
    }

    .StandartPageIn{
        padding-left: 14px;
        padding-right: 14px;
    }

    .StandartPageInRight{
        padding-right: 14px;
    }

}

.HrGrayVerticalLine{height: 100%; width: 1px;  background-color: #0a79bf;}


@media only screen and (min-width: 500px) {
    .StandartPhone {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .StandartNoPhone {
        display: none;
    }
}
@media only screen and (min-width: 700px) {
    .StandartPhone700 {
        display: none;
    }
}

@media only screen and (max-width: 700px) {
    .StandartNoPhone700 {
        display: none;
    }
    .StandartPhone700 {
        display: block;
    }
}


.ButtonYellowDiv{
    display: flex;
    justify-content: center;
    padding-bottom: 39px;
}




.ButtonYellow{
    font-weight: bold;
    font-size: 19px;
    height: 50px;
    border-radius: 4px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 48px;
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
    background-image: linear-gradient(to bottom, #ffe588, #ffb553);
    color: black;
    text-align: center;
    cursor: pointer;
}

.ButtonYellow:hover{
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
    background-image: linear-gradient(to bottom, #ffd063, #ff8732);
}

.ButtonYellow:active{
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.15);
    background-color: #ffbf58;
}


.ButtonYellow>a{
    text-decoration: none;
}

.ButtonYellow>a:visited{
    color: inherit;
}



.ButtonTransparency{
    font-family: Geometria, sans-serif;
    font-weight: 700;
    font-size: 15px;
    height: 46px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 43px;
    border-radius: 4px;
    border: solid 2px #f4f5f7;
    background: none;
    color: white;
    text-align: center;
}

.ButtonTransparency:hover{
    border-style: dotted;
}

.ButtonTransparency:active{
    background-color:  #0a79bf;
}

.ButtonTransparency:active>nobr{
    background-color:  #0a79bf;
    color: white;
}


.ButtonBlack{
    font-weight: bold;
    font-size: 14px;
    height: 38px;
    border-radius: 4px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 38px;
    border: solid 2px #161645;
    background: none;
    color: #151544;
    text-align: center;
}

.ButtonBlack:hover{
    border-color:  #ffb14e;
}

.ButtonBlack:active{
    border-radius: 4px;
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.15);
    background-color: #ffbf58;
}

.Button404{
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 17px;
    height: 34px;
    line-height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #d7d8dc;
    color: #5e667b;
    text-align: center;
    cursor: pointer;

}

.Button404:hover{
    background-color: white;
    border: 1px solid #8a8092;
    transition: all .2s linear;
}

.Button404:active{
    background-color:  #2f4563;
    border-color:  #2f4563;
    color: white;
}

.ButtonBlack{
    font-weight: bold;
    font-size: 14px;
    height: 38px;
    border-radius: 4px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 38px;
    border: solid 2px #161645;
    background: none;
    color: #151544;
    text-align: center;
}

.ButtonBlack:hover{
    border-color:  #ffb14e;
}

.ButtonBlack:active{
    border-radius: 4px;
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.15);
    background-color: #ffbf58;
}

.ButtonYellow{
    font-weight: bold;
    font-size: 19px;
    height: 50px;
    border-radius: 4px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 48px;
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
    background-image: linear-gradient(to bottom, #ffe588, #ffb553);
    color: black;
    text-align: center;
    cursor: pointer;
}

.ButtonYellow:hover{
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
    background-image: linear-gradient(to bottom, #ffd063, #ff8732);
}

.ButtonYellow:active{
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.15);
    background-color: #ffbf58;
}


.ButtonYellow>a{
    text-decoration: none;
}

.ButtonYellow>a:visited{
    color: inherit;
}


.ButtonTransparency{
    font-family: Geometria, sans-serif;
    font-weight: 700;
    font-size: 15px;
    height: 46px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 43px;
    border-radius: 4px;
    border: solid 2px #f4f5f7;
    background: none;
    color: white;
    text-align: center;
}

.ButtonTransparency:hover{
    border-style: dotted;
}

.ButtonTransparency:active{
    background-color:  #0a79bf;
}

.ButtonTransparency:active>nobr{
    background-color:  #0a79bf;
    color: white;
}

.ButtonYellowImgLine{
    position: relative;
    top: 2px;
}

.ButtonBlackOpacity{
    font-size: 14px;
    font-weight: 700;
    color: black;
    padding: 10px 20px 10px 20px;
    border: solid black 2px;
    border-radius: 4px;
}

.AdvantageBlackInGridRightButton{
    font-size: 14px;
    font-weight: 700;
    color: black;
    padding: 10px 20px 10px 20px;
    border: solid black 2px;
    border-radius: 4px;
    position: relative;
    top: -40px;
    left: 40px;
}


.ButtonWhite{
    font-weight: bold;
    font-size: 14px;
    height: 38px;
    border-radius: 4px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 38px;
    border: solid 2px white;
    background: none;
    color: white;
    text-align: center;
}

.ButtonWhite:hover{
    border-color:  #ffb14e;
}

.ButtonWhite:active{
    border-radius: 4px;
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.15);
    background-color: #ffbf58;
}
.html404TopButton{
    font-weight: bold;
    font-size: 14px;
    height: 38px;
    border-radius: 20px;
    padding-left: 23px;
    padding-right: 23px;
    line-height: 38px;
    border:  2px solid #aa86a5;
    background: none;
    color: #023776;
    text-align: center;
    margin-right: 26px;
}

.html404TopButton:hover{
    border-color:  #560d4b;
    color: #560d4b;
}

.html404TopButton:active{
    border-radius: 4px;
    box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.15);
}