.TradingOpenDemo{
    max-width: 1078px;
    margin: auto;
    padding-bottom: 15px;
    background-color: #eef1f2;
}


.TradingOpenDemoText{
    font-size: 15px;
    color: rgb(24,24,24);
    padding-top: 22px;
    padding-bottom: 15px;
}

.TradingOpenDemoIn {
    max-width: 1078px;
    margin: auto;
}

.TradingOpenDemoInWhite{
    background-color: white;
    padding-left: 25px;
    padding-bottom: 15px;
}


@media only screen and (max-width: 700px) {
    .TradingOpenDemoIn {
        padding-left: 0;
    }
    .TradingOpenDemoIn>div {
        padding-left: 15px;
    }
}

.TradingOpenDemoInWhiteH1{
    font-size: 21px;
    color: #181818;
    line-height: 23px;
    padding-top: 20px;
}
.TradingOpenDemoInWhiteText{
    font-size: 16px;
    color: #181818;
    line-height: 40px;
    padding-top: 20px;
}
.TradingOpenDemoInWhiteTextNumber{
    display: grid;
    grid-template-columns: 23px auto;
    grid-column-gap: 12px;
    max-width: 600px;
}
.TradingOpenDemoInWhiteTextNumber>div>a{
    padding-left: 5px;
}

.TradingOpenDemoTextNumber{
    width: 23px;
    height: 23px;
    border-radius: 23px;
    background-color: #0a79bf;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.TradingOpenDemoTextNumberDown{
    position: relative;
    top: 5px;
}

.TradingOpenDemoInWhite>img{
    width: 100%;
}

.TradingOpenDemoTextNumber>div{
    position: relative;
    top: -1px;
    left: 0;
}

.DemoBaalConvLeftIMGPhone{
    display: none;
}

.TradingOpenDemoIMG01{
    max-width: 404px;
}
.TradingOpenDemoIMG02{
    max-width: 538px;
}
.TradingOpenDemoIMG03{
    max-width: 549px;
}
.TradingOpenDemoIMG04{
    max-width: 964px;
}
.TradingOpenDemoIMG05{
    max-width: 942px;
}
.TradingOpenDemoIMG06{
    max-width: 968px;
}

.TradingOpenDemoInTitleBottom{
    font-size: 28px;
    color: #388d40;
    line-height: 23px;
    padding-top: 32px;
}


.DemoBaalConv{
    max-width: 1078px;
    margin: auto;
    display: grid;
    grid-template-columns: 55% 45%;
    margin-top: 40px;
    padding-bottom: 60px;
}

.DemoBaalConvLeft>img{
    position: relative;
    float: right;
}

.DemoBaalConvRight{
    grid-column: 2;
}

@media only screen and (max-width: 1150px) {
    .DemoBaalConv{
        grid-template-columns: minmax(100px, 500px) minmax(470px,480px);
    }
}

@media only screen and (max-width: 650px) {
    .AboutBaalTopInText{
        padding-bottom: 20px;
    }
    .DemoBaalConv{
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }
    .DemoBaalConvRight{
        grid-column: 1;
        grid-row: 2;
    }

}

.DemoBaalConvRightHeader{
    padding-top: 84px;
    color: rgb(42,51,60);
    font-family: GothamPro, sans-serif;
    font-size: 33px;
}

.DemoBaalConvRightText{
    padding-right: 30px;
    margin-top: 40px;
    color: rgb(48,56,65);
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.DemoBaalConvRightButtons{
    width: 100%;
    margin-top: 49px;
    display: grid;
    grid-template-columns: auto 4fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;

}
.DemoBaalConvRightButtons{
    width: 90%;
    margin: auto;
    padding: 50px 0 35px 0;
}
@media only screen and (max-width: 650px) {
    .DemoBaalConvRightHeader{
        font-size: 22px;
        padding-left: 29px;
        padding-top: 20px;
    }
    .DemoBaalConvRightText{
        padding-left: 29px;
    }

}


@media only screen and (max-width: 500px) {

    .DemoBaalConvRightButtons {
        grid-template-columns: 1fr 20px 20px 20px 20px 20px 20px 1fr;
        grid-column-gap: 20px;

    }

    .DemoBaalConvRightButtonsMain {
        grid-row: 1;
        grid-column: 1 /span 8;
        padding-bottom: 23px;
    }

    .AboutBaalMacOs{
        margin-left: 0;
    }
}

.DemoBaalConvTwoHeader{
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;

}


@media only screen and (max-width: 650px) {

    .DemoBaalConvTwoElementIn{
        width: auto;
    }
    .DemoBaalConvLeftIMGPhone{
        display: flex;
        position: relative;
        justify-content: center;
    }
    .DemoBaalConvLeft>img{
        float: initial;
        margin: auto;
    }
    .DemoBaalConvLeftIMGDesk{
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .DemoBaalConvTwoElementIn{
        width: 90% !important;
    }
}

@media only screen and (max-width: 400px) {
    .DemoBaalConvTwo>.DemoBaalConvTwoHeader{
        font-size: 20px;
    }

}

@media only screen and (max-width: 340px) {
    .DemoBaalConvRight>.DemoBaalConvRightButtons{
        grid-column-gap: 20px;
        grid-template-columns: 1fr 15px 15px 15px 15px 1fr;
    }
    .DemoBaalConvLeftIMGPhone{
        width: 100%;
    }

    .DemoBaalConvRightButtonsMain>a>.ButtonBlueSmall{
        padding-right: 20px;
        padding-left: 20px;
    }

}

