.TraderBonuses>.StandartTitle{
    padding-bottom: 40px;
}

.TraderBonusesPart01{
    display: grid;
    grid-template-columns: auto 334px;
}

.TraderBonusesPart01Img>img{
    display: none;
}


@media only screen and (max-width: 800px) {
    .TraderBonusesPart01{
        grid-template-columns: auto auto;
    }
}

.TraderBonusesPart01Img{
    background: url("../../../static/img/TraderBonuses/TraderBonuses01.png") no-repeat right bottom;
    background-position-y: 0;
}

.TraderBonusesPart01Grid{
    /*display: grid;*/
    /*grid-template-columns:auto auto;*/
    /*grid-template-rows: auto auto 50px;*/
    /*grid-column-gap: 25px;*/
    height: auto;
    margin-top: 82px;
}

.TraderBonusesPart01GridElement{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TraderBonusesPart01GridElementTitle{
    font-weight: bold;
    font-size: 80px;
    color: #66c400;
}

.TraderBonusesPart01GridElementText{
    font-weight: 400;
    font-size: 40px;
    color: black;
    padding-top: 10px;
    padding-bottom: 20px;

}

.TraderBonusesPart01GridElementTextComission{
    width: 230px;
}

.TraderBonusesPart01GridButton{
    grid-column: 1/3;
}

.TraderBonusesPart02Text{
    font-weight: 400;
    font-size: 16px;
    color: black;
    line-height: 27px;
}

.TraderBonusesPart02Grid{
    display: grid;
    padding-top: 40px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 55px;
}

@media only screen and (max-width: 800px) {
    .TraderBonusesPart02Grid{
        grid-template-columns: auto;
        grid-row-gap: 20px;
    }
    .TraderBonusesPart01{
        padding-left: 20px;
        padding-right: 20px;
    }

}

.TraderBonusesPart03{
    text-align: center;
    background-image: linear-gradient(249deg, #ffe588, #ffb553);
    margin-top: 45px;
}

.TraderBonusesPart03Text{
    font-weight: 400;
    font-size: 20px;
    color: black;
    line-height: 29px;
}

.TraderBonusesPart03Img{
    padding-top: 50px;
    padding-bottom: 27px;
}

.TraderBonusesPart03Button{
    padding-top: 27px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 700px) {
    .TraderBonusesPart01{
        display: block;
    }
    .TraderBonusesPart01Img{
        background: none;
        display: flex;
        justify-content: center;
    }

    .TraderBonusesPart01Img>img{
        display: block;
    }
    .TraderBonusesPart01Grid{
        margin-top: 10px;
    }
}